import { Center, HStack } from '@chakra-ui/react';
import Icon from '@designs/primitives/common/icon';
import Body1C from '@designs/primitives/typography/body-1-c';
import Body2B from '@designs/primitives/typography/body-2-b';
import { useHarmony } from '@hooks/use-harmony';
import TextUtils from '@utils/text.utils';
import { useTranslations } from 'next-intl';
import { TimerProps } from '..';

const TimerD: React.FC<TimerProps> = ({ isStarted, isEnded, timeRemaining, ...props }) => {
    const { theme } = useHarmony();
    const t = useTranslations();
    const [days, hours, minutes, seconds] = timeRemaining;

    return (
        <Center h="32px" bgColor={theme.primary.default} {...props}>
            <HStack spacing="8px">
                <Icon color={theme.icon.white} size={20} fill>
                    alarm_on
                </Icon>
                {isStarted && !isEnded && (
                    <HStack spacing="4px">
                        {/* Days */}
                        {days > 0 && (
                            <HStack spacing="2px">
                                <Body1C color={theme.text.title1}>{days}</Body1C>
                                <Body2B color={theme.text.title1}>DAYS</Body2B>
                            </HStack>
                        )}

                        {/* Time */}
                        <HStack spacing="2px">
                            <Body1C color={theme.text.title1}>{TextUtils.formatCountTime(hours)}</Body1C>
                            <Body1C color={theme.text.title1}>:</Body1C>
                            <Body1C color={theme.text.title1}>{TextUtils.formatCountTime(minutes)}</Body1C>
                            <Body1C color={theme.text.title1}>:</Body1C>
                            <Body1C color={theme.text.title1}>{TextUtils.formatCountTime(seconds)}</Body1C>
                        </HStack>
                    </HStack>
                )}
                {!isStarted && <Body1C color={theme.text.title1}>{t('home.saleStarts')}</Body1C>}
                {isEnded && <Body1C color={theme.text.title1}>{t('home.saleHasEnded')}</Body1C>}

                <Icon color={theme.icon.white} size={20} fill>
                    alarm_on
                </Icon>
            </HStack>
        </Center>
    );
};

export default TimerD;
