import { BoxProps } from '@chakra-ui/react';
import { useDesignComponents } from '@hooks/use-design-components';
import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import SwipeSubMenuCellA from './designA/swipe-sub-menu-cell';
import SwipeSubMenuCellB from './designB/swipe-sub-menu-cell';
import SwipeSubMenuCellC from './designC/swipe-sub-menu-cell';
import SwipeSubMenuCellD from './designD/swipe-sub-menu-cell';

export interface SwipeSubMenuCellProps extends BoxProps {
    isActive?: boolean;
    onClick: () => void;
}

const SwipeSubMenuCell: React.FC<SwipeSubMenuCellProps> = (props) => {
    const Component = useDesignComponents<ComponentType<SwipeSubMenuCellProps>>({
        [Design.DesignA]: SwipeSubMenuCellA,
        [Design.DesignB]: SwipeSubMenuCellB,
        [Design.DesignC]: SwipeSubMenuCellC,
        [Design.DesignD]: SwipeSubMenuCellD,
    });

    return <Component {...props} />;
};

export default SwipeSubMenuCell;
