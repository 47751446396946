import { useDesignComponents } from '@hooks/use-design-components';
import { Design } from '@type/harmony-config';
import { BaseSelectableComponentProps } from '@type/theme';
import { ComponentType } from 'react';
import SwipeSubMenuA from './designA/swipe-sub-menu';
import SwipeSubMenuC from './designC/swipe-sub-menu';
import SwipeSubMenuD from './designD/swipe-sub-menu';
import SwipeSubMenuB from './designB/swipe-sub-menu';

export interface SwipeSubMenuProps<T> extends BaseSelectableComponentProps<T> {}

const SwipeSubMenu = <T,>(props: SwipeSubMenuProps<T>) => {
    const Component = useDesignComponents<ComponentType<SwipeSubMenuProps<T>>>({
        [Design.DesignA]: SwipeSubMenuA,
        [Design.DesignB]: SwipeSubMenuB,
        [Design.DesignC]: SwipeSubMenuC,
        [Design.DesignD]: SwipeSubMenuD,
    });

    return <Component {...props} />;
};

export default SwipeSubMenu;
