import { HStack } from '@chakra-ui/react';
import SwipeSubMenuCell from '@designs/primitives/main/sub-menu/swipe-sub-menu-cell';
import { useHarmony } from '@hooks/use-harmony';
import { SwipeSubMenuProps } from '..';

const SwipeSubMenuB = <T,>({ items, onChange, selectedItem }: SwipeSubMenuProps<T>) => {
    const { theme } = useHarmony();

    return (
        <HStack spacing="10px" w="100%" p="8px 16px" overflowX="auto">
            {items.map((item, index) => (
                <SwipeSubMenuCell onClick={() => onChange(item)} isActive={item.value === selectedItem?.value} key={index}>
                    {item.label}
                </SwipeSubMenuCell>
            ))}
        </HStack>
    );
};

export default SwipeSubMenuB;
