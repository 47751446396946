import { Box, VStack } from '@chakra-ui/react';
import { useHarmony } from '@hooks/use-harmony';
import BodyText from '@legacy/components/common/text/body-text';
import { SwipeSubMenuCellProps } from '..';

const SwipeSubMenuCellA: React.FC<SwipeSubMenuCellProps> = ({ isActive, onClick, children, ...props }) => {
    const { theme } = useHarmony();

    return (
        <Box onClick={onClick} p="4px 8px" cursor="pointer" flexShrink="0" {...props}>
            <VStack spacing="8px" align="stretch">
                <BodyText size="16px" weight={isActive ? 'bold' : 'regular'} lineHeight="20px" color={isActive ? theme.black : theme.gray[600]} whiteSpace="nowrap">
                    {children}
                </BodyText>
                <Box h="4px" bgColor={isActive ? theme.secondary.default || theme.primary.default : ''} />
            </VStack>
        </Box>
    );
};

export default SwipeSubMenuCellA;
