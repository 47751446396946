import { Center } from '@chakra-ui/react';
import { useHarmony } from '@hooks/use-harmony';
import BodyText from '@legacy/components/common/text/body-text';
import { SwipeSubMenuCellProps } from '..';

const SwipeSubMenuCellC: React.FC<SwipeSubMenuCellProps> = ({ isActive, onClick, children, ...props }) => {
    const { theme } = useHarmony();

    return (
        <Center onClick={onClick} bgColor={isActive ? theme.black : theme.gray[50]} px="12px" h="32px" rounded="full" {...props}>
            <BodyText color={isActive ? theme.white : theme.gray[600]} size="16px" lineHeight="100%" weight={isActive ? 'bold' : 'regular'} whiteSpace="nowrap">
                {children}
            </BodyText>
        </Center>
    );
};

export default SwipeSubMenuCellC;
