import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import ThemeUtils from '@utils/theme.utils';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import BodyText from 'src/legacy/components/common/text/body-text';
import { TabBarProps } from '..';
import { useDragScroll } from '@hooks/use-mouse-drag';

const TabBarA: React.FC<TabBarProps> = ({ frameTopMenu }) => {
    const { asPath } = useRouter();
    const { theme } = useHarmony();
    const t = useTranslations();
    const router = useRouter();
    const dragScroll = useDragScroll();

    return (
        <div
            css={css`
                flex-shrink: 0;
                position: sticky;
                top: 0;
                left: 0;
                display: flex;
                align-items: center;
                flex-wrap: nowrap;
                width: 100%;
                height: 52px;
                padding: 0px 16px;
                overflow-x: auto;
                overflow-y: hidden;
                background-color: ${theme.primary[600]};
                z-index: ${ThemeUtils.zIndex.header};
                user-select: none;
            `}
            {...dragScroll}
        >
            {frameTopMenu &&
                frameTopMenu.items.map((item, i) => {
                    return (
                        <BarLink
                            role="button"
                            onClick={() => router.push(item.pageUrl)}
                            key={i}
                            size="17px"
                            color={theme.white}
                            weight={asPath === item.pageUrl ? 'bold' : 'thin'}
                            active={asPath === item.pageUrl}
                        >
                            {item.label}
                        </BarLink>
                    );
                })}
        </div>
    );
};

export default TabBarA;

const BarLink = styled(BodyText)<{ active?: boolean }>`
    ${({ active }) => `
        cursor: pointer;
        flex-shrink: 0;
        margin-right: 16px;
        position: relative;
        ${
            active
                ? `
            :after {
                display: block;
                content: '';
                position: absolute;
                top: 24px;
                width: 100%;
                border-bottom: 2px solid white;
            }
        `
                : ''
        }
    `}
`;
