import { useDesignComponents } from '@hooks/use-design-components';

import { TimeRemaining } from '@hooks/use-countdown';
import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import TimerD from './designD/timer';
import { CenterProps } from '@chakra-ui/react';

export interface TimerProps extends CenterProps {
    timeRemaining: TimeRemaining;
    isStarted?: boolean;
    isEnded?: boolean;
}

const Timer: React.FC<TimerProps> = (props) => {
    const Component = useDesignComponents<ComponentType<TimerProps>>({
        [Design.DesignD]: TimerD,
    });

    return <Component {...props} />;
};

export default Timer;
