import { Center } from '@chakra-ui/react';
import { useHarmony } from '@hooks/use-harmony';
import BodyText from '@legacy/components/common/text/body-text';
import { SwipeSubMenuCellProps } from '..';

const SwipeSubMenuCellB: React.FC<SwipeSubMenuCellProps> = ({ isActive, onClick, children, ...props }) => {
    const { theme } = useHarmony();

    return (
        <Center
            onClick={onClick}
            bgColor={isActive ? theme.primary.default : theme.gray[50]}
            borderWidth="1px"
            borderStyle="solid"
            borderColor={isActive ? theme.primary.default : theme.gray[200]}
            px="14px"
            h="30px"
            rounded="full"
            {...props}
        >
            <BodyText color={isActive ? theme.white : theme.gray[600]} size="15px" lineHeight="18px" weight={isActive ? 'bold' : 'regular'} whiteSpace="nowrap">
                {children}
            </BodyText>
        </Center>
    );
};

export default SwipeSubMenuCellB;
