import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import React from 'react';
import MoreViewButtonC from './designC/more-view-button';

export interface MoreViewButtonProps {
    onClick?: () => void;
    margin?: string;
    hasDivider?: boolean;
}

const MoreViewButton: React.FC<MoreViewButtonProps> = ({ ...props }) => {
    const Component = useDesignComponents<typeof MoreViewButtonC>({
        [Design.DesignC]: MoreViewButtonC,
    });
    return <Component {...props} />;
};

export default MoreViewButton;
