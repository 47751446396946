import { Box, HStack } from '@chakra-ui/react';
import TopMenuCell from '@designs/primitives/main/top-menu/top-menu-cell';
import { useHarmony } from '@hooks/use-harmony';
import ThemeUtils from '@utils/theme.utils';
import { useRouter } from 'next/router';
import { TopMenuInnerProps } from '..';

const TopMenuD = ({ items }: TopMenuInnerProps) => {
    const { theme } = useHarmony();
    const router = useRouter();

    return (
        <Box as="nav" pos="sticky" top="0px" left="0px" right="0px" zIndex={ThemeUtils.zIndex.header}>
            <HStack spacing="4px" p="8px 16px" overflowX="auto" bgColor={theme.background.white} boxShadow={theme.dropShadow.shadow2}>
                {items.map((item, index) => (
                    <TopMenuCell key={index} href={item.pageUrl} isActive={item.pageUrl === router.asPath.split('?')[0]}>
                        {item.label}
                    </TopMenuCell>
                ))}
            </HStack>
        </Box>
    );
};

export default TopMenuD;
