import CustomIcon from 'src/legacy/components/common/custom-icon';
import BodyText from 'src/legacy/components/common/text/body-text';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { IThemeProps } from '@type/common';
import { useTranslations } from 'next-intl';
import { MoreViewButtonProps } from '..';

const MoreViewButtonC: React.FC<MoreViewButtonProps> = ({ onClick, margin, hasDivider = true }) => {
    const { theme } = useHarmony();
    const t = useTranslations();

    return (
        <MoreViewButton role={'button'} theme={theme} onClick={onClick} margin={margin} hasDivider={hasDivider}>
            <BodyText size="17px" color={theme.gray[600]} lineHeight="18px">
                {t('common.viewMore')}
            </BodyText>
            <CustomIcon size="16px" color={theme.gray[600]}>
                chevron_right
            </CustomIcon>
        </MoreViewButton>
    );
};

export default MoreViewButtonC;

const MoreViewButton = styled.div<IThemeProps & MoreViewButtonProps>`
    width: 100%;
    padding: 11px 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    ${({ theme, margin, hasDivider }) => `
        border-top: ${hasDivider ? `1px solid ${theme.gray[50]}` : 'none'};
        margin: ${margin};
    `}
`;
