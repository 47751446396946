import BodyText from 'src/legacy/components/common/text/body-text';
import { useHarmony } from '@hooks/use-harmony';
import { useTranslations } from 'next-intl';
import { CountdownTextProps } from '..';

const CountdownTextB: React.FC<CountdownTextProps> = ({ timeRemaining }) => {
    const { theme } = useHarmony();
    const [day, hour, min, sec] = timeRemaining;
    const t = useTranslations();

    return (
        <BodyText weight="bold" size="24px" font="secondary" letterSpacing="12%" color={theme.primary.default}>
            {day}
            <BodyText weight="bold" size="24px" letterSpacing="12%" color={theme.primary.default}>
                {t('home.countdownDay')}
                {`  `}
            </BodyText>
            {`  `}
            {`  `}
            {hour}
            {`  `}:{`  `}
            {min}
            {`  `}:{`  `}
            {sec}
        </BodyText>
    );
};

export default CountdownTextB;
