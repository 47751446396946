import { useDesignComponents } from '@hooks/use-design-components';

import FrameTopMenu from '@models/frame-collection/frame-top-menu';
import { Design } from '@type/harmony-config';
import DomainUtils from '@utils/domain.utils';
import { ComponentType } from 'react';
import TabBarA from './designA/tab-bar';
import TabBarB from './designB/tab-bar';
import TabBarC from './designC/tab-bar';

export interface TabBarProps {
    frameTopMenu?: FrameTopMenu;
}

const TabBar: React.FC<TabBarProps> = (props) => {
    if (DomainUtils.isNhPay || DomainUtils.isNhPayNormal) {
        return <></>;
    }

    return <TabBarInner {...props} />;
};

const TabBarInner: React.FC<TabBarProps> = (props) => {
    const Component = useDesignComponents<ComponentType<TabBarProps>>({
        [Design.DesignA]: TabBarA,
        [Design.DesignB]: TabBarB,
        [Design.DesignC]: TabBarC,
    });

    return <Component {...props} />;
};

export default TabBar;
