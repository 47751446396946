import styled from '@emotion/styled';
import { useExtension } from '@hooks/use-extension';
import { ImageProps } from 'next/image';
import CustomImage from './custom-image';
import { ScreenSizeProps } from '@type/common';

export interface CustomResizeableImageProps extends ImageProps {
    src: string;
    paddingX?: string;
    imageHeightFactor?: number;
    borderRadius?: string;
    children?: React.ReactNode;
    boxShadow?: string;
}

const CustomResizeableImage: React.FC<CustomResizeableImageProps> = ({ src, imageHeightFactor, paddingX = '32px', borderRadius, width, height, children, boxShadow, ...props }) => {
    const { extensionStatus } = useExtension();
    return (
        <ImageContainer
            imageHeightFactor={imageHeightFactor}
            borderRadius={borderRadius}
            paddingX={paddingX}
            width={width}
            height={height}
            boxShadow={boxShadow}
            maxScreenWidth={extensionStatus?.maxScreenWidth}
        >
            <CustomImage src={src} width={width} height={height} {...props} />
            {children}
        </ImageContainer>
    );
};

export default CustomResizeableImage;

const ImageContainer = styled.div<Pick<CustomResizeableImageProps, 'paddingX' | 'imageHeightFactor' | 'borderRadius' | 'width' | 'height' | 'boxShadow'> & ScreenSizeProps>`
    ${({ imageHeightFactor, paddingX, borderRadius, width, height, boxShadow, maxScreenWidth }) => `
    position: relative;
    width: ${imageHeightFactor ? '100%' : `${width}px`};
    height: ${imageHeightFactor ? `calc((min(100vw, ${maxScreenWidth}px) - ${paddingX}) * ${imageHeightFactor})` : `${height}px`};
    border-radius: ${borderRadius};
    overflow: hidden;
    box-shadow: ${boxShadow};
  `}
`;
