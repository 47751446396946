import { Box } from '@chakra-ui/react';
import CustomLink from '@components/custom-link';
import StatefulText from '@components/stateful-text';
import Body1B from '@designs/primitives/typography/body-1-b';
import Body1D from '@designs/primitives/typography/body-1-d';
import { useHarmony } from '@hooks/use-harmony';
import { TopMenuCellProps } from '..';

const TopMenuCellD: React.FC<TopMenuCellProps> = ({ isActive, children, ...props }) => {
    const { theme } = useHarmony();

    return (
        <CustomLink {...props}>
            <Box p="4px" flexShrink="0">
                <StatefulText as={Body1B} color={theme.text.body2} states={[{ as: Body1D, condition: isActive, color: theme.text.title1 }]}>
                    {children}
                </StatefulText>
            </Box>
        </CustomLink>
    );
};

export default TopMenuCellD;
