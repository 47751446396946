import BodyText from 'src/legacy/components/common/text/body-text';
import { useHarmony } from '@hooks/use-harmony';
import { useTranslations } from 'next-intl';
import { CountdownTextProps } from '..';

const CountdownTextA: React.FC<CountdownTextProps> = ({ timeRemaining }) => {
    const { theme } = useHarmony();
    const t = useTranslations();
    const [day, hour, min, sec] = timeRemaining;

    return (
        <BodyText weight="thin" size="15px" color={theme.white}>
            <BodyText color={theme.white} weight="bold" size="20px" font="secondary">
                {day}
            </BodyText>
            {t('home.countdownDay')}
            {`  `}
            <BodyText color={theme.white} weight="bold" size="20px" font="secondary">
                {hour}
            </BodyText>
            {t('home.countdownHour')}
            {`  `}
            <BodyText color={theme.white} weight="bold" size="20px" font="secondary">
                {min}
            </BodyText>
            {t('home.countdownMin')}
            {`  `}
            <BodyText color={theme.white} weight="bold" size="20px" font="secondary">
                {sec}
            </BodyText>
            {t('home.countdownSec')}
            {`  `}
            {t('home.countdown')}
        </BodyText>
    );
};

export default CountdownTextA;
