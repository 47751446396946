import { Center } from '@chakra-ui/react';
import Body1A from '@designs/primitives/typography/body-1-a';
import { useHarmony } from '@hooks/use-harmony';
import { EmptyMessageProps } from '..';

const EmptyMessageD: React.FC<EmptyMessageProps> = ({ children, isCenterOnScreen }) => {
    const { theme } = useHarmony();

    return (
        <Center w="100%" {...(isCenterOnScreen && { pos: 'absolute', top: '50%', transform: 'auto', translateY: '-50%', left: '0', right: '0' })}>
            <Body1A color={theme.text.body2} textAlign="center" whiteSpace="pre-wrap">
                {children}
            </Body1A>
        </Center>
    );
};

export default EmptyMessageD;
