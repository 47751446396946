import { useDesignComponents } from '@hooks/use-design-components';

import { useHarmony } from '@hooks/use-harmony';
import FrameMenuItem from '@models/frame-collection/frame-menu-item';
import RoutesEnum from '@routes';
import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import TopMenuD from './designD/top-menu';

interface TopMenuProps {}

export interface TopMenuInnerProps {
    items: FrameMenuItem[];
}

const TopMenu = (props: TopMenuProps) => {
    const Component = useDesignComponents<ComponentType<TopMenuInnerProps>>({
        [Design.DesignD]: TopMenuD,
    });

    const { frameCollection } = useHarmony();
    const items = frameCollection?.topMenuFrame?.items.filter((item, index) => index === 0 || item.pageUrl !== RoutesEnum.Home) || [];

    return <Component items={items} {...props} />;
};

export default TopMenu;
