import { useDesignComponents } from '@hooks/use-design-components';

import { BodyTextProps } from 'src/legacy/components/common/text/body-text';
import { TimeRemaining } from '@hooks/use-countdown';
import { Design } from '@type/harmony-config';
import CountdownTextA from './designA/countdown-text';
import CountdownTextB from './designB/countdown-text';
import CountdownTextC from './designC/countdown-text';

export interface CountdownTextProps extends Omit<BodyTextProps, 'children'> {
    timeRemaining: TimeRemaining;
}

const CountdownText: React.FC<CountdownTextProps> = (props) => {
    const Component = useDesignComponents<typeof CountdownTextA | typeof CountdownTextB | typeof CountdownTextC>({
        [Design.DesignA]: CountdownTextA,
        [Design.DesignB]: CountdownTextB,
        [Design.DesignC]: CountdownTextC,
    });

    return <Component {...props} />;
};

export default CountdownText;
