import { Box, VStack } from '@chakra-ui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import ThemeUtils from '@utils/theme.utils';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import BodyText from 'src/legacy/components/common/text/body-text';
import { TabBarProps } from '..';
import { useDragScroll } from '@hooks/use-mouse-drag';

const TabBarB: React.FC<TabBarProps> = ({ frameTopMenu }) => {
    const { asPath } = useRouter();
    const { theme } = useHarmony();
    const t = useTranslations();
    const router = useRouter();
    const dragScroll = useDragScroll();

    return (
        <div
            css={css`
                flex-shrink: 0;
                position: sticky;
                top: 0;
                left: 0;
                display: flex;
                align-items: center;
                flex-wrap: nowrap;
                width: 100%;
                height: 48px;
                padding: 0px 16px;
                overflow-x: auto;
                overflow-y: hidden;
                background-color: ${theme.white};
                z-index: ${ThemeUtils.zIndex.header};
                user-select: none;
            `}
            {...dragScroll}
        >
            {frameTopMenu &&
                frameTopMenu.items.map((item, i) => {
                    return (
                        <BarLink
                            role="button"
                            onClick={() => router.push(item.pageUrl)}
                            key={i}
                            size="17px"
                            color={asPath === item.pageUrl ? theme.black : theme.gray[400]}
                            weight={asPath === item.pageUrl ? 'bold' : 'regular'}
                            active={asPath === item.pageUrl}
                        >
                            <VStack spacing="8px">
                                <div>{item.label}</div>
                                <Box bgColor={asPath === item.pageUrl ? theme.primary.darken[100] : theme.white} w="100%" h="3px" borderRadius="full" />
                            </VStack>
                        </BarLink>
                    );
                })}
        </div>
    );
};

export default TabBarB;

const BarLink = styled(BodyText)<{ active?: boolean }>`
    cursor: pointer;
    flex-shrink: 0;
    margin-right: 16px;
    padding-top: 16px;
`;
