import BodyText from 'src/legacy/components/common/text/body-text';
import { useHarmony } from '@hooks/use-harmony';
import { useTranslations } from 'next-intl';
import { CountdownTextProps } from '..';
import styled from '@emotion/styled';
import { BlockHStack } from 'src/legacy/components/blockui';

const CountdownTextC: React.FC<CountdownTextProps> = ({ timeRemaining, size, color, weight }) => {
    const { theme } = useHarmony();
    const [day, hour, min, sec] = timeRemaining;
    const t = useTranslations();

    return (
        <Container>
            {day > 0 && (
                <BlockHStack spacing="none" alignItems="flex-end">
                    <BodyText weight="bold" size="18px" color={theme.primary.default}>
                        {day}
                    </BodyText>
                    <BodyText size="18px" color={theme.primary.default}>
                        {t('home.countdownDay2')}
                    </BodyText>
                </BlockHStack>
            )}
            <BlockHStack spacing="none" alignItems="flex-end">
                <BodyText weight="bold" size="18px" color={theme.primary.default}>
                    {hour}
                </BodyText>
                <BodyText size="18px" color={theme.primary.default}>
                    {t('home.countdownHour2')}
                </BodyText>
            </BlockHStack>
            <BlockHStack spacing="none" alignItems="flex-end">
                <BodyText weight="bold" size="18px" color={theme.primary.default}>
                    {min}
                </BodyText>
                <BodyText size="18px" color={theme.primary.default}>
                    {t('home.countdownMin2')}
                </BodyText>
            </BlockHStack>
            <SecWrapper spacing="1px" alignItems="flex-end" sec={sec}>
                <BodyText className="sec" weight="bold" size="18px" color={theme.primary.default}>
                    {sec}
                </BodyText>
                <BodyText size="18px" color={theme.primary.default}>
                    {t('home.countdownSec2')}
                </BodyText>
            </SecWrapper>
        </Container>
    );
};

export default CountdownTextC;

const Container = styled.div`
    display: flex;
    align-items: flex-end;
    gap: 6px;
    height: 18px;
`;

const SecWrapper = styled(BlockHStack)<{ sec: number }>`
    .sec {
        display: inline-block;
        text-align: right;
        min-width: ${({ sec }) => (sec > 19 ? '21px' : sec > 9 ? '18px' : '11px')};
    }
`;
