import { Box, VStack } from '@chakra-ui/react';
import StatefulText from '@components/stateful-text';
import Body2B from '@designs/primitives/typography/body-2-b';
import Body2C from '@designs/primitives/typography/body-2-c';
import { useHarmony } from '@hooks/use-harmony';
import { SwipeSubMenuCellProps } from '..';

const SwipeSubMenuCellD: React.FC<SwipeSubMenuCellProps> = ({ isActive, onClick, children, ...props }) => {
    const { theme } = useHarmony();

    return (
        <Box onClick={onClick} p="4px 8px" cursor="pointer" flexShrink="0" {...props}>
            <VStack spacing="2px" align="stretch">
                <StatefulText
                    as={Body2B}
                    color={theme.text.body2}
                    states={[
                        {
                            as: Body2C,
                            color: theme.text.title1,
                            condition: isActive,
                        },
                    ]}
                    whiteSpace="nowrap"
                >
                    {children}
                </StatefulText>
                <Box h="2px" bgColor={isActive ? theme.text.title1 : ''} />
            </VStack>
        </Box>
    );
};

export default SwipeSubMenuCellD;
