import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import { ComponentType, ReactNode } from 'react';
import EmptyMessageD from './designD/empty-message';

export interface EmptyMessageProps {
    children: ReactNode;

    /**
     * When there are no results for a list that uses the entire page,
     * such as search results or order history, the message is centered on the screen.
     */
    isCenterOnScreen?: boolean;
}

const EmptyMessage: React.FC<EmptyMessageProps> = (props) => {
    const Component = useDesignComponents<ComponentType<EmptyMessageProps>>({
        [Design.DesignD]: EmptyMessageD,
    });

    return <Component {...props} />;
};

export default EmptyMessage;
