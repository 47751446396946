import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import FrameMenuItem from '@models/frame-collection/frame-menu-item';
import ThemeUtils from '@utils/theme.utils';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import BodyText from 'src/legacy/components/common/text/body-text';
import { TabBarProps } from '..';
import { useDragScroll } from '@hooks/use-mouse-drag';

const TabBarC: React.FC<TabBarProps> = ({ frameTopMenu }) => {
    const { asPath } = useRouter();
    const { theme } = useHarmony();
    const router = useRouter();
    const activeLinkRef = useRef<HTMLButtonElement>(null);
    const dragScroll = useDragScroll();

    useEffect(() => {
        if (activeLinkRef.current) {
            activeLinkRef.current.scrollIntoView();
        }
    }, []);

    return (
        <>
            <Container {...dragScroll}>
                {frameTopMenu &&
                    frameTopMenu.items.map((item: FrameMenuItem, i) => {
                        const active = asPath === item.pageUrl;

                        return (
                            <BarLink ref={active ? activeLinkRef : null} onClick={() => router.push(item.pageUrl)} key={i} active={active}>
                                <BodyText size="17px" color={active ? theme.white : theme.gray[600]} weight={active ? 'bold' : 'regular'}>
                                    {item.label}
                                </BodyText>
                            </BarLink>
                        );
                    })}
            </Container>
            <Box h="54px" />
        </>
    );
};

export default TabBarC;

const Container = styled.div`
    flex-shrink: 0;
    position: fixed;
    top: 54px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    height: 56px;
    padding: 8px 16px;
    overflow-x: auto;
    overflow-y: hidden;
    background-color: white;
    border-radius: 0 0 20px 20px;
    z-index: ${ThemeUtils.zIndex.header};
    user-select: none;
`;

const BarLink = styled.button<{ active?: boolean }>`
    display: flex;
    flex-shrink: 0;
    padding: ${({ active }) => (active ? '9px 16px' : '8px 12px')};
    margin-right: 6px;
    background-color: ${({ active }) => (active ? 'black' : 'white')};
    border-radius: ${({ active }) => (active ? '100px' : '')};
`;
