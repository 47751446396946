import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import { LinkProps } from 'next/link';
import { ComponentType, ReactNode } from 'react';
import TopMenuCellD from './designD/top-menu-cell';

export interface TopMenuCellProps extends LinkProps {
    isActive?: boolean;
    children?: ReactNode;
}

const TopMenuCell: React.FC<TopMenuCellProps> = (props) => {
    const Component = useDesignComponents<ComponentType<TopMenuCellProps>>({
        [Design.DesignD]: TopMenuCellD,
    });

    return <Component {...props} />;
};

export default TopMenuCell;
