import { useEffect, useMemo, useState } from 'react';

export type TimeRemaining = [days: number, hours: number, minutes: number, seconds: number];
type CountDown = {
    time: TimeRemaining;
    end: boolean;
};

const useCountdown = (targetDate?: Date, isEnabled = true): CountDown => {
    const countDownDate: number = useMemo(() => targetDate?.getTime() || 0, [targetDate]);
    const [countDown, setCountDown] = useState<number>(countDownDate - new Date().getTime());

    useEffect(() => {
        if (isEnabled) {
            const interval = setInterval(() => {
                setCountDown(countDownDate - new Date().getTime());
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [countDownDate, isEnabled]);

    useEffect(() => {
        setCountDown(countDownDate - new Date().getTime());
    }, [countDownDate]);

    return {
        time: getReturnValues(countDown),
        end: !isEnabled || countDown <= 0,
    };
};

const getReturnValues = (countDown: number): TimeRemaining => {
    // calculate time left
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    return [days, hours, minutes, seconds];
};

export { useCountdown };
